(function() {
    if (document.querySelector('#vue-images-upload') != null) {
        var VueImagesUpload = new Vue({
            el: '#vue-images-upload',
            data: function() {
                return {
                    photos: [],
                    dropzone: null,
                    singular: '',
                    id: '',
                    type: '',
                    fotos: '',
                    order_changed: false
                }
            },
            mounted: function() {
                // Closure
                var self = this;
    
                this.$data.singular = document.querySelector('#singular').getAttribute('value');
                this.$data.id = document.querySelector('#id').getAttribute('value');
                this.$data.type = document.querySelector('#type').getAttribute('value');
    
                this.fetch_pictures();
    
                this.start_dropzone();

                this.start_sortable();
            },
            methods: {
                start_dropzone: function() {
                    // Closure
                    var self = this;
                    Dropzone.autoDiscover = false;
                    this.$data.dropzone = new Dropzone("div#dz", { 
                        url: "/photo.upload.php",
                    });
    
                    this.$data.dropzone.on('sending', function(file, xhr, formData){
                        formData.append("type", self.$data.type);
                        formData.append("singular", self.$data.singular);
                        formData.append("key", self.$data.id);
                    });
                    
                    this.$data.dropzone.on('succcess', function(){
                        self.fetch_pictures();
                    });
                },
    
                fetch_pictures: function() {
                    // Closure
                    var self = this;
    
                    reqwest({
                        url: 'photo.fetch.php',
                        type: 'json',
                        method: 'post',
                        data: {
                            type: self.$data.type,
                            singular: self.$data.singular,
                            id: self.$data.id
                        },
                        success: function(resp) {
                            console.log(resp);
                            self.$data.fotos = resp;
                        },
                        error: function(resp) {
                            console.log(resp);
                        }
                    });
                },

                delete_file: function(file_id) {
                    // Closure
                    var self = this;

                    reqwest({
                        url: 'photo.delete.php',
                        type: 'json',
                        method: 'post',
                        data: {
                            id: file_id,
                            type: self.$data.type,
                            singular: self.$data.singular,
                        },
                        success: function(resp) {
                            console.log(resp);
                            self.fetch_pictures();
                        },
                        error: function(resp) {
                            console.log(resp);
                            self.fetch_pictures();
                        }
                    });

                    return false;
                },

                save_order: function() {
                    // Closure
                    var self = this;

                    var handles = document.querySelectorAll('.sortable-handle');
                    var ids = [];
                    if (handles != null && handles.length > 0) {
                        for(var i = 0; i < handles.length; i++) {
                            ids.push(handles[i].getAttribute('data-id'));
                        }

                        console.log(ids);

                        reqwest({
                            url: 'photo.save-order.php',
                            type: 'json',
                            method: 'post',
                            data: {
                                id_array: ids,
                                type: self.$data.type,
                                singular: self.$data.singular,
                            },
                            success: function(resp) {
                                console.log(resp);
                            },
                            error: function(resp) {
                                console.log(resp);
                            }
                        });
                        
                    }

                    self.$data.order_changed = false;
                },

                start_sortable: function() {
                    // Closure
                    var self = this;

                    var el = document.getElementById('sortable');
                    var sortable = Sortable.create(el, {
                        handle: '.sortable-handle',
                        dataIdAttr: 'data-order',
                        animation: 150,
                        onStart: function (evt) {
                            self.$data.order_changed = true;
                        },
                    
                    });
                }
            }
        })
    }
})();