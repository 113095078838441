(function() {
    var App = {
        // -----------------------------------------
        // Init
        // -----------------------------------------
        init: function() {
            // Closure
            var self = this;

            this.startSweetAlert();
            this.startTooltips();
            this.startSlick();
        },

        // -----------------------------------------
        // Start Sweet Alert
        // -----------------------------------------
        startSweetAlert: function() {
            // Closure
            var self = this;

            var forms = document.querySelectorAll('.needs-confirmation');
            console.log(forms);
            if (forms != null && forms.length > 0) {
                for (var i = 0; i < forms.length; i++) {
                    forms[i].addEventListener('click', function(ev) {
                        ev.preventDefault();
                        var form = this;
                        swal({
                            title: "Bent u zeker?",
                            text: "Dit is een actie die niet ongedaan kan gemaakt worden.",
                            icon: "warning",
                            showCancelButton: true,
                            dangerMode: true,
                          }, function (willDelete) {
                            if (willDelete) {
                              form.submit();
                            }
                        });
                    });
                }
            }
        },

        // -----------------------------------------
        // Start tooltips
        // -----------------------------------------
        startTooltips: function() {
            // Closure
            var self = this;

            $('[data-toggle="tooltip"]').tooltip();
        },

        // -----------------------------------------
        // Slick
        // -----------------------------------------
        startSlick: function() {
            // Closure
            var self = this;

            $('.slick-col-3').slick({
                dots: false,
                infinite: true,
                speed: 600,
                slidesToShow: 3,
                slidesToScroll: 3,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 6000,
                responsive: [
                    {
                    breakpoint: 992,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
                ]
            });

            $('.slider').slick({
                dots: false,
                infinite: true,
                speed: 600,
                slidesToShow: 1,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 6000,
                fade: true,
            });

            $('.slick-col-4').slick({
                dots: false,
                infinite: true,
                speed: 600,
                slidesToShow: 4,
                slidesToScroll: 4,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 6000,
                responsive: [
                    {
                    breakpoint: 992,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 3,
                        }
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2
                        }
                    },
                    {
                        breakpoint: 680,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
                ]
            });
        }
    }

    App.init();
})();