(function() {
    if (document.querySelector('#vue-integration') != null) {
        var integrator = new Vue({
            el: '#vue-integration',
            data: function() {
                return {
                    userId: null,
                    selected: {
                        colors: {
                            accent: '82BE37'
                        },
                        font: {
                            family: 'open_sans',
                            size: 14
                        },
                        lang: 'nl',
                        type: 'verblijven'
                    }
                }
            },
            mounted: function() {
                // Closure
                var self = this;

                this.$data.userId = this.$el.getAttribute('data-user-id');
                this.initColorPicker();
            },
            methods: {
                initColorPicker: function() {
                    // CLosure
                    var self = this;

                    var picker = new CP(document.querySelector('input.color-picker'));
                    picker.on("change", function(color) {
                        this.target.value = color;
                        self.$data.selected.colors.accent = color;
                    });
                }
            }
        })
    }
})();